/*!
* COVID 19 Quaratine Monitoring Dashboard, Bhutan
*/
import React from "react";
import ReactDOM from "react-dom";
import App from './App';

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

//axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
// axios.defaults.withCredentials = true;
// axios.defaults.crossDomain = true;


ReactDOM.render(
  <App />,
  document.getElementById("root")
);
