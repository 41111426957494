import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import AdminNavbarLinks from "./AdminNavbarLinks";

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  // const sidebarMinimize =
  //   classes.sidebarMinimize +
  //   " " +
  //   cx({
  //     [classes.sidebarMinimizeRTL]: rtlActive
  //   });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        
        <div className={classes.flex}>
          <h2 className={classes.title}>{brandText}</h2>
          {/* Here we create navbar brand, based on route name */}
        </div>
        <AdminNavbarLinks rtlActive={rtlActive} />
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};
