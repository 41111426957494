import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import axios from "axios";
import { createBrowserHistory } from "history";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import React, { useEffect } from 'react';
import { Redirect, Router, Switch } from "react-router-dom";
import PrivateRoute from "Utils/PrivateRoute.js";
import PublicRoute from "Utils/PublicRoute.js";
import { getToken, getUser, removeUserSession, setUserDzongkhagSession, setUserSession } from './Utils/Common.js';



const hist = createBrowserHistory();

function App() {

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    axios.get("/api/method/frappe.auth.get_loggedin_user_details").then(res=>{
      
      if(res.status === 200){
          if(res.data.login_id === getUser()){
            setUserSession(res.data.api_key, res.data.api_secret, res.data.login_id);
            var url = `/api/resource/User%20Account/`+res.data.login_id;

            axios.get(url).then(resp=>{
                  setUserDzongkhagSession(resp.data.data.dzongkhag);
                  //  return;
                  // props.history.push('/admin/dashboard');
              }).catch(function (error){
                  // setLoading(false);
                  // setError(error.response.data.message);
              }).then(function(){     
          });
          }
          else{
              removeUserSession();
          }
      }
      else{
        removeUserSession();
      }
    });
}, []);

  return (
    <div className="App">
        <Router history={hist}>
            <Switch>
                <PublicRoute path="/auth" component={AuthLayout} />
                <PrivateRoute path="/admin" component={AdminLayout} />
                <Redirect from="/" to="/auth/login" />
            </Switch>
        </Router>
    </div>
  );
}
export default App;