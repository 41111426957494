import React from "react";
import Cases from "views/Cases/Cases.js";

export default function Dashboard() {

  return (
    <div>
        <Cases />
    </div >
  );
}
