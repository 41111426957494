import CardMedia from '@material-ui/core/CardMedia';
import DefaultAvatar from "assets/img/default-avatar.png";
import axios from "axios";
import React from 'react';


export default function SelfieImage(props) {

  const [profilePic, setprofilePic] = React.useState(DefaultAvatar);

  if(props.profileUrl == null){
    return (
        <CardMedia
            component="img"
            alt="Selfie Image"
            height="140"
            image={DefaultAvatar}
            title="Default"
        />
      );
  }
  else{
   // setprofilePic(DefaultAvatar);
    const url = `/api/method/covid.tracker_api.convert_base64?image=${props.profileUrl}`;
    axios.get(url).then(res=>{
        if(res.data.message.data ===""){
            setprofilePic(DefaultAvatar);
        }
        else{
            setprofilePic(`${res.data.message.data}`);
        }
    });
  }
  return (
    <CardMedia
        component="img"
        alt="Selfie Image"
        height="140"
        image={profilePic}
        title="Selfie Image"
    />
  );
}