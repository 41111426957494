import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import DefaultAvatar from "assets/img/default-avatar.png";
import axios from "axios";
import React from 'react';


// User Tag Unreachable
// User Tag Battery Low
// Reference Tag Unreachable
// Reference Tag Battery Low
// Phone Battery Low
// Phone Not Reachable
const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: 'red',
    color: 'red',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(5)',
      opacity: 0,
    },
  },
}))(Badge);


const StyledBadge1 = withStyles(theme => ({
  badge: {
    backgroundColor: 'orange',
    color: 'orange',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.4s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(5)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadge2 = withStyles(theme => ({
  badge: {
    backgroundColor: 'black',
    color: 'black',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.6s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(5)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadge3 = withStyles(theme => ({
  badge: {
    backgroundColor: 'blue',
    color: 'blue',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.8s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(5)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadge4 = withStyles(theme => ({
  badge: {
    backgroundColor: '#00f3ff',
    color: '#00f3ff',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 2.1s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(5)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadge5 = withStyles(theme => ({
  badge: {
    backgroundColor: 'green',
    color: 'green',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 4s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(5)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadge6 = withStyles(theme => ({
  badge: {
    backgroundColor: 'purple',
    color: 'purple',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 3s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(5)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadge7 = withStyles(theme => ({
  badge: {
    backgroundColor: 'lime',
    color: 'lime',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 3s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(5)',
      opacity: 0,
    },
  },
}))(Badge);

const avatarImageStyle = {
  width: 50,
  height: 50,
};

export default function CaseProfileIcon(props) {
  const [caseType] = React.useState(props.caseType);
  const [profilePic, setprofilePic] = React.useState();

  if(props.profileUrl == null){

  }
  else{
    const url = `/api/method/covid.tracker_api.convert_base64?image=${props.profileUrl}`;
    axios.get(url).then(res=>{
      setprofilePic(`${res.data.message.data}`);
    });
  }
  
  if(caseType === "Symptoms"){
    return (
      <StyledBadge
      overlap="circle"
      anchororigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
    >
      {profilePic ?(
        <Avatar alt="Profile Picture" src={profilePic} style={avatarImageStyle}/>
      ):(
        <Avatar alt="Profile Picture" src={DefaultAvatar} style={avatarImageStyle}/>
      )
      }
    </StyledBadge>
    )
  }
  else if(caseType === "Perimeter Breach"){
    
    return (<StyledBadge1
        overlap="circle"
        anchororigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        {profilePic ?(
        <Avatar alt="Profile Picture" src={profilePic} style={avatarImageStyle}/>
      ):(
        <Avatar alt="Profile Picture" src={DefaultAvatar} style={avatarImageStyle}/>
      )
      }
      </StyledBadge1>
    )
  }
  else if(caseType === "No Symptoms Update"){
    return (<StyledBadge2
      overlap="circle"
      anchororigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
    >
     {profilePic ?(
        <Avatar alt="Profile Picture" src={profilePic} style={avatarImageStyle}/>
      ):(
        <Avatar alt="Profile Picture" src={DefaultAvatar} style={avatarImageStyle}/>
      )
      }
    </StyledBadge2>
  )
  }
  else if(caseType === "User Tag Unreachable" || 
          caseType === "User Tag Battery Low" || 
          caseType === "Reference Tag Unreachable" ||
          caseType === "Reference Tag Battery Low" ||
          caseType === "Phone Battery Low" ||
          caseType === "Phone Not Reachable"
  ){
    return (
      <StyledBadge7
        overlap="circle"
        anchororigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        {profilePic ?(
        <Avatar alt="Profile Picture" src={profilePic} style={avatarImageStyle}/>
      ):(
        <Avatar alt="Profile Picture" src={DefaultAvatar} style={avatarImageStyle}/>
      )
      }
      </StyledBadge7>
    )
  }
  else if(caseType === "No Selfie Update"){
    return (
      <StyledBadge3
        overlap="circle"
        anchororigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        {profilePic ?(
        <Avatar alt="Profile Picture" src={profilePic} style={avatarImageStyle}/>
      ):(
        <Avatar alt="Profile Picture" src={DefaultAvatar} style={avatarImageStyle}/>
      )
      }
      </StyledBadge3>
    )
  }
  else if(caseType === "Selfie Mismatch"){
    return (
      <StyledBadge5
        overlap="circle"
        anchororigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        {profilePic ?(
        <Avatar alt="Profile Picture" src={profilePic} style={avatarImageStyle}/>
      ):(
        <Avatar alt="Profile Picture" src={DefaultAvatar} style={avatarImageStyle}/>
      )
      }
      </StyledBadge5>
    )
  }
  else if(caseType === "Home Location Not Set"){
    return (
      <StyledBadge6
        overlap="circle"
        anchororigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        {profilePic ?(
        <Avatar alt="Profile Picture" src={profilePic} style={avatarImageStyle}/>
      ):(
        <Avatar alt="Profile Picture" src={DefaultAvatar} style={avatarImageStyle}/>
      )
      }
      </StyledBadge6>
    )
  }
  return (
    <div>
      <StyledBadge6
        overlap="circle"
        anchororigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        {profilePic ?(
        <Avatar alt="Profile Picture" src={profilePic} style={avatarImageStyle}/>
      ):(
        <Avatar alt="Profile Picture" src={DefaultAvatar} style={avatarImageStyle}/>
      )
      }
      </StyledBadge6>
    </div>
  );
}
