import Dashboard from "views/Dashboard/Dashboard.js";
import SubjectCaseDetails from "views/Cases/SubjectCaseDetails.js";
import Login from "views/auth/Login.js";
import Logout from "views/auth/Logout.js";
import NewCases from "views/Cases/NewCases.js";
import PendingActionCases from "views/Cases/PendingActionCases.js";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SelfieVerifications from "views/SelfieVerifications/SelfieVerifications.js";


var dashRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/selfieverification",
    name: "Selfie Verification",
    mini: "PP",
    component: SelfieVerifications,
    layout: "/admin"
  },
  {
    path: "/subject-case-details/:caseid/:subjectid",
    name: "Subject Case Details",
    mini: "PP",
    component: SubjectCaseDetails,
    layout: "/admin"
  },
  {
    path: "/cases/new/:casetype",
    name: "New Cases",
    mini: "PP",
    component: NewCases,
    layout: "/admin"
  },
  {
    path: "/cases/new",
    name: "New Cases",
    mini: "PP",
    component: NewCases,
    layout: "/admin"
  },
  {
    path: "/cases/pending",
    name: "Pending Action Cases",
    mini: "Pending Action",
    component: PendingActionCases,
    layout: "/admin"
  },
  {
    path: "/cases/pending/:casetype",
    name: "Pending Action Cases",
    mini: "Pending Action",
    component: PendingActionCases,
    layout: "/admin"
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
    layout: "/auth"
  }
];
export default dashRoutes;
