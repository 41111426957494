import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import AddAlert from "@material-ui/icons/AddAlert";
import DoneAll from "@material-ui/icons/DoneAll";
import HighlightOff from "@material-ui/icons/HighlightOff";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import axios from "axios";
import Card from "components/Card/Card.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import React, { useState } from "react";
import ItemsCarousel from 'react-items-carousel';
import SelfieImage from "views/SelfieVerifications/SelfieImage.js";

const useStyles = makeStyles(styles);

export default function SelfieLoader(props) {

    const classes = useStyles();
  
    const [bl, setBL] = React.useState(false);
    const [br, setBR] = React.useState(false);
    var selfieData = props.selfiedata;
    const [data] = React.useState(selfieData);
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const [verified, setVerified] = useState(new Array(selfieData.length).fill(false));

    var currentVerification = verified;

    const verify = (e, verification_status, name, key) => {

        const newVerified = verified;
        var verificationStatus = null;
        if (verification_status === "match") {
            verificationStatus = "Match"
        }
        if (verification_status === "mismatch") {
            verificationStatus = "No Match"
        }

        if (typeof name != 'undefined' && verificationStatus != null) {

            axios.put("/api/resource/Selfie/" + name, {
                "status": verificationStatus
            }).then(function (response) {
                
                showNotification('br');
                newVerified[key] = true;
                setVerified(newVerified);
                setActiveItemIndex(key + 1);

            }).catch(error => {
                showNotification('bl');
            });
        }
        else {
            showNotification('bl');
            return;
        }
    }

    const showNotification = place => {
        switch (place) {
            case "bl":
                if (!bl) {
                    setBL(true);
                    setTimeout(function () {
                        setBL(false);
                    }, 3000);
                }
                break;
            case "br":
                if (!br) {
                    setBR(true);
                    setTimeout(function () {
                        setBR(false);
                    }, 3000);
                }
                break;
            default:
                break;
        }
    };
    return (
        <>
            <Grid container className={classes.root} spacing={5}>
                <Grid item xs={12}>
                    {(data) ? (
                        <ItemsCarousel
                            infiniteLoop={false}
                            gutter={12}
                            activePosition={'left'}
                            chevronWidth={60}
                            disableSwipe={false}
                            alwaysShowChevrons={false}
                            numberOfCards={4}
                            slidesToScroll={1}
                            outsideChevron={true}
                            showSlither={false}
                            firstAndLastGutter={false}
                            activeItemIndex={activeItemIndex}
                            requestToChangeActive={setActiveItemIndex}
                            rightChevron={'>'}
                            leftChevron={'<'}
                        >
                            {data.map((prop, key) => (

                                <Grid xs={12} item key={key} value={prop.name} >
                                    <Card className={classes.root}>
                                        <CardContent>
                                            <Grid container justify="center" spacing={0}>
                                                <Grid item xs={6}>
                                                    <SelfieImage profileUrl={prop.initial_image} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SelfieImage profileUrl={prop.selfie} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            {(!currentVerification[key]) ? (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="baseline" spacing={2}
                                                >
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            value={prop.name}
                                                            className={classes.button}
                                                            onClick={(e) => verify(e, "match", prop.name, key)}
                                                        >
                                                            <DoneAll className={classes.icons} />
                                                            YES
                                                    </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            className={classes.button}
                                                            onClick={(e) => verify(e, "mismatch", prop.name, key)}
                                                        >
                                                            <HighlightOff className={classes.icons} />
                                                        NO
                                                    </Button>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="baseline" spacing={2}
                                                    >
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled
                                                                value={prop.name}
                                                                className={classes.disabledButton}
                                                            >
                                                                <DoneAll className={classes.icons} />
                                                            Selfie Verified
                                                    </Button>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                            {/* <SelfieLoader key={key} selfiedata={data.data} /> */}
                        </ItemsCarousel>

                    ) : (
                            <>
                            </>
                        )
                    }
                    <Snackbar
                        place="br"
                        color="success"
                        icon={AddAlert}
                        message="Selfie Verification successful!"
                        open={br}
                        closeNotification={() => setBR(false)}
                        close
                    />
                    <Snackbar
                        place="bl"
                        color="danger"
                        icon={AddAlert}
                        message="Could not update the selfie verification. Please try again!"
                        open={bl}
                        closeNotification={() => setBL(false)}
                        close
                    />
                    {/* </Grid> */}
                </Grid>
            </Grid>
        </>
    )
}