import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import useAxios from 'axios-hooks';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useState } from "react";
import Loader from 'react-loader-spinner';
import SelfieLoader from "views/SelfieVerifications/SelfieLoader.js";

const useStyles = makeStyles(styles);

export default function SelfieVerifications() {

  const classes = useStyles();
  var url = "/api/resource/Selfie?limit_page_length=10&fields=[%22*%22]&filters=[[%22status%22,%22=%22,%22Pending%22]]"
  const [selfieUrl] = useState(url);
  
  const [{ data, loading, error }, refetch] = useAxios(selfieUrl);

  const loadMoreSelfies = () => {
    refetch(true);
  }
 
  if (error) {
    return (
      <>
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h1 className={classes.title}>{error.status}</h1>
              <h2 className={classes.subTitle}>Server Error!</h2>
              <h4 className={classes.description}>
                Ooooups! Something went wrong. Please try to reload the page.
            </h4>
            </GridItem>
          </GridContainer>
        </div>
      </>
    )

  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Selfie Verifications Cases
                            </h4>
            </CardHeader>
            <CardBody>
              <br />

              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  {/* <SuspectCaseDetails /> */}

                  {loading ? (
                    <div className={classes.contentCenter}>
                      <Box display="flex" flexDirection="row" justifyContent="center" p={1} m={1}>
                        <Box p={1}>
                          <Loader
                            type="Bars"
                            color="#00BFFF"
                            height={50}
                            width={50}
                          />
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="row" justifyContent="center" p={0} m={0}>
                        Loading Selfie Verifications
                      </Box>
                    </div>
                  ) : (
                      <>
                        {(data == null) ? (<>

                          No Selfie Verifications to display

                        </>) : (
                            <>
                              <SelfieLoader selfiedata={data.data} />
                            </>
                          )}
                      </>
                    )
                  }
                </GridItem>
              </GridContainer>

              <Box display="flex" flexDirection="row" justifyContent="flex-end" p={1} m={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={loadMoreSelfies}>
                  Load More Selfie Verifications
                  </Button>
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div >
  );
}