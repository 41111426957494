import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Icon from "@material-ui/core/Icon";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import DefaultAvatar from "assets/img/default-avatar.png";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import useAxios from 'axios-hooks';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useState } from "react";
import Loader from 'react-loader-spinner';
import CaseProfileIcon from "views/Cases/CaseProfileIcon.js";
// import { makeStyles } from "@material-ui/core/styles";
import { getDzongkhag } from '../../Utils/Common.js';


const useStyles = makeStyles(styles);

const avatarImageStyle = {
    width: 50,
    height: 50,
};

const GetSubjectDetails = (props) => {

    var url = `/api/resource/Subject/${props.subject_id}`;

    const [{ data, loading, error }, ] = useAxios(url);

    if (error) {
        return;
    }
    if (!loading) {
        if (data.data.initial_image != null) {
            return (
                <CaseProfileIcon caseType={props.caseType} profileUrl={data.data.initial_image} />
            )
        }
        else {
            return (
                <CaseProfileIcon caseType={props.caseType} profileUrl={data.data.latest_image} />
            )
        }
    }
    else {
        return (
            <Avatar alt="Profile Image" src={DefaultAvatar} style={avatarImageStyle} />
        )
    }
}


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

function Cases() {
    const classes = useStyles();
    const [spacing] = React.useState(2);

    var styleRed = {
        color: "red"
    }
    var styleOrange = {
        color: "orange"
    }
    var styleBlack = {
        color: "black"
    }
    var styleBlue = {
        color: "blue"
    }
    var stylePurple = {
        color: "purple"
    }
    var styleGreen = {
        color: "green"
    }

    var styleCyan = {
        color: "#00f3ff"
    }

    var styleLime = {
        color: "lime"
    }

    var pageLength = 50;
    var limitStart = 0;
    const [caseUrl, setcaseUrl] = useState(`/api/resource/Case?limit_page_length=${pageLength}&limit_start=${limitStart}&fields=[%22*%22]&filters=[[%22status%22,%22=%22,%22Open%22],[%22suspect_status%22,%22!=%22,%22Isolation%22]]`);
    const [previousButtonInActive, setpreviousButtonInActive] = useState(true)
    const [nextButtonInActive, setnextButtonInActive] = useState(false)
    const [{ data, loading, error }, ] = useAxios(caseUrl);



    // var url = `/api/resource/Subject/${props.profileId}`;

    // const filterMethod = (filter, row, column) => {
    //     const id = filter.pivotId || filter.id
    //     return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
    // }

    function handlePrevious() {
        if (limitStart > 0) {

            limitStart = limitStart - pageLength;
            //setpageLength(pageLength+10);
            setcaseUrl(`/api/resource/Case?limit_page_length=${pageLength}&limit_start=${limitStart}&fields=[%22*%22]&filters=[[%22status%22,%22=%22,%22Open%22],[%22dzongkhag%22,%22=%22,%22${getDzongkhag()}%22],[%22suspect_status%22,%22!=%22,%22Isolation%22]]`);
            
        }
        else if (limitStart === 0) {
            limitStart = 0;
            //setpageLength(pageLength+10);
            setnextButtonInActive(false);
            setpreviousButtonInActive(true);
            setcaseUrl(`/api/resource/Case?limit_page_length=${pageLength}&limit_start=${limitStart}&fields=[%22*%22]&filters=[[%22status%22,%22=%22,%22Open%22],[%22dzongkhag%22,%22=%22,%22${getDzongkhag()}%22],[%22suspect_status%22,%22!=%22,%22Isolation%22]]`);
            
        }
        else {
            setnextButtonInActive(false);
            setpreviousButtonInActive(false);
        }

    }

    function handleNext() {
        if (data.data.length < pageLength) {
            setnextButtonInActive(true);
            setpreviousButtonInActive(false);
            return;
        }
        if (limitStart === 0) {
            limitStart = pageLength;
            setnextButtonInActive(false);
            setpreviousButtonInActive(true);
        }
        else {
            limitStart = limitStart + pageLength;
            setnextButtonInActive(false);
            setpreviousButtonInActive(false);
        }
        // setpageLength(pageLength);
        setcaseUrl(`/api/resource/Case?limit_page_length=${pageLength}&limit_start=${limitStart}&fields=[%22*%22]&filters=[[%22status%22,%22=%22,%22Open%22],[%22dzongkhag%22,%22=%22,%22${getDzongkhag()}%22],[%22suspect_status%22,%22!=%22,%22Isolation%22]]`);
    }
    if (error) {
        return (
            <>
                <Box display="flex" flexDirection="row" justifyContent="center" p={0} m={0}>
                    <h2 className={classes.subTitle}>Server Error!</h2>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center" p={0} m={0}>
                    <h4 className={classes.description}>
                        Ooooups! Something went wrong. Please try to reload the page.
                    </h4>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center" p={0} m={0}>
                    <h4 className={classes.description}>
                        <a href="/admin/cases"> Try Reloading the Page </a>
                    </h4>
                </Box>
            </>
        )
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="danger" icon>
                            <CardIcon color="danger">
                                <Icon>info_outline</Icon>
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Case Alerts
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end" p={1} m={1}>
                                <Box p={1}>
                                    <a href="/admin/cases/new/Bluetooth" style={styleLime} ><i className={classes.socialButtonsIcons + " " + classes.marginRight + " fa fa-dot-circle"} style={styleLime} />{" "}Bluetooth</a>
                                </Box>
                                <Box p={1}>
                                    <a href="/admin/cases/new/Symptoms" style={styleRed} ><i className={classes.socialButtonsIcons + " " + classes.marginRight + " fa fa-dot-circle"} style={styleRed} />{" "}Symptoms</a>
                                </Box>
                                <Box p={1}>
                                    <a href="/admin/cases/new/Perimeter Breach" style={styleOrange}><i className={classes.socialButtonsIcons + " " + classes.marginRight + " fa fa-dot-circle"} style={styleOrange} />{" "}Perimeter Breach</a>
                                </Box>
                                <Box p={1}>
                                    <a href="/admin/cases/new/No Symptoms Update" style={styleBlack} ><i className={classes.socialButtonsIcons + " " + classes.marginRight + " fa fa-dot-circle"} style={styleBlack} />{" "}No Symptoms Update</a>
                                </Box>
                                <Box p={1}>
                                    <a href="/admin/cases/new/No Selfie Update" style={styleBlue}><i className={classes.socialButtonsIcons + " " + classes.marginRight + " fa fa-dot-circle"} style={styleBlue} />{" "}No Selfie Update</a>
                                </Box>
                                <Box p={1}>
                                    <a href="/admin/cases/new/No GPS Update" style={styleCyan}><i className={classes.socialButtonsIcons + " " + classes.marginRight + " fa fa-dot-circle"} style={styleCyan} />{" "}No GPS Update</a>
                                </Box>
                                <Box p={1}>
                                    <a href="/admin/cases/new/Selfie Mismatch" style={styleGreen} ><i className={classes.socialButtonsIcons + " " + classes.marginRight + " fa fa-dot-circle"} style={styleGreen} />{" "}Selfie Mismatch</a>
                                </Box>
                                <Box p={1}>
                                    <a href="/admin/cases/new/Home Location Not Set" style={stylePurple}><i className={classes.socialButtonsIcons + " " + classes.marginRight + " fa fa-dot-circle"} style={stylePurple} />{" "}Home Location Not Set</a>
                                </Box>
                            </Box>
                            <br />
                            <GridContainer justify="space-between">
                                <GridItem xs={12} sm={12} md={12}>
                                    {loading ? (
                                        <div className={classes.contentCenter}>
                                            <Box display="flex" flexDirection="row" justifyContent="center" p={1} m={1}>
                                                <Box p={1}>
                                                    <Loader
                                                        type="Bars"
                                                        color="#00BFFF"
                                                        height={50}
                                                        width={50}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box display="flex" flexDirection="row" justifyContent="center" p={0} m={0}>
                                                Loading Case Alerts
                                            </Box>
                                        </div>
                                    ) : (
                                            <>
                                                {(data === null) ? (<>
                                                    <Box display="flex" flexDirection="row" justifyContent="center" p={0} m={0}>
                                                        No cases to display
                                                    </Box>
                                                </>) : (
                                                        <>
                                                            <GridContainer justify="space-between">
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <Grid container className={classes.root} spacing={5}>
                                                                        <Grid item xs={12}>
                                                                            <Grid container spacing={spacing}>
                                                                                {data.data.map((prop, key) => (
                                                                                    <Grid key={key} item>
                                                                                        <HtmlTooltip placement="top"
                                                                                            title={
                                                                                                <React.Fragment>
                                                                                                    <Typography color="inherit">{prop.case_type}</Typography>
                                                                                                    <Typography color="inherit">{prop.suspect_name}</Typography>
                                                                                                    <Typography color="inherit">{prop.name}</Typography>
                                                                                                    <Typography color="inherit">{prop.suspect_status}</Typography>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        >
                                                                                            <a href={`/admin/subject-case-details/${prop.name}/${prop.suspect}`} >
                                                                                                <GetSubjectDetails caseType={prop.case_type} subject_id={prop.suspect} />
                                                                                            </a>
                                                                                        </HtmlTooltip>
                                                                                    </Grid>
                                                                                ))}
                                                                            </Grid>
                                                                        </Grid>

                                                                    </Grid>
                                                                </GridItem>
                                                            </GridContainer>
                                                            <Box display="flex" flexDirection="row" justifyContent="flex-end" p={1} m={1}>
                                                                <Button onClick={handlePrevious}
                                                                    disabled={previousButtonInActive}>
                                                                    <KeyboardArrowLeft /> PREVIOUS
                                </Button>
                                                                <Button onClick={handleNext} disabled={nextButtonInActive}>
                                                                    NEXT <KeyboardArrowRight />
                                                                </Button>
                                                            </Box>
                                                        </>
                                                    )}
                                            </>
                                        )
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        </>
    );
}
export default Cases;